// This is the template for a single sign
import * as React from "react";
import { graphql, Link, navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Modal from "../components/modal";
import { ArrowRight, SignalEye } from "../images/icons";
import { undelineSM } from "../utils/cssGroups";
import { slugify } from "../utils/slugify";
import { Seo } from "../components/seo";
// ------------> Sign (single) page <------------ //
const SignPageTemplate = (props) => {
  // --->  Data <--- //
  const sign = props.data.strapiSign;
  const { categories, design_by, concept_by, signal, locationContext } =
    props.data.strapiSign;
  const nextSign = props.pageContext.nextSignSlug;
  // const prevSign = props.pageContext.previousSignSlug;

  // --->  Gatsby Images <--- //
  const heroImage = getImage(
    sign.heroImage.localFile.childImageSharp.gatsbyImageData
  );
  const locationImage = getImage(
    sign.locationImage.localFile.childImageSharp.gatsbyImageData
  );

  // ---> Modal Setup <--- //
  const [showModal, setShowModal] = React.useState(false);
  const handleOnClose = () => setShowModal(false);

  return (
    <Layout
      location={props.location}
      bodyOverflow={showModal ? "overflow-hidden" : "overflow-auto"}
    >
      <div className="text-white">
        {/* ---> Hero Image <--- */}
        <div className="flex justify-center mb-[2.6rem]">
          {sign.heroImage && (
            <GatsbyImage
              image={heroImage}
              alt=""
              className="center"
              aspectratio={1 / 1}
            />
          )}
        </div>
        <div id="texts" className="w-full lg:w-2/3 ">
          {/* ---> Title <--- */}
          <div className="text-lg md:text-xl uppercase font-normal  mb-[2.6rem]">
            {sign.title}
          </div>
          {/* ---> Year <--- */}
          <div className={"mb-[2.6rem]"}>
            <div className={"text-amber-300"}> Year:</div>{" "}
            <div className="md:text-md ">{sign.year}</div>
          </div>
          {/* ---> Description <--- */}
          <div className={"mb-[2.6rem]"}>
            <div className={"text-amber-300"}>Description:</div>
            <div className="md:text-md">{sign.description}</div>
          </div>

          {/* ---> Issuer <--- */}
          <div className={"mb-[2.6rem]"}>
            <div className={"text-amber-300"}> Issuer:</div>{" "}
            <div className="md:text-md capitalize mt-0">{sign.issuer}</div>
          </div>

          {/* ---> Signals Modal <--- */}
          <div className="mb-[2.6em] text-amber-300">
            <div className="flex items-center content-center ">
              <div className={`mr-5`}>Signal (or how did we get here?): </div>
              <SignalEye classes={"text-abmer-300 h-5 pt-1"} />
            </div>
            <Modal
              modalData={signal}
              visible={showModal}
              onClose={handleOnClose}
            />
            <button
              className={`text-left md:text-md group transition ease-in-out`}
              onClick={() => setShowModal(true)}
            >
              <div className="text-black mt-2 relative block max-w-full leading-[2.2rem] md:leading-[3.3rem] mb-[1.3rem]">
                <div className="inline bg-amber-300 italic box-decoration-clone px-2 group-hover:bg-white duration-300">
                  {signal.signalHighlight}
                </div>
              </div>

              <ArrowRight
                classes={
                  "h-3 md:h-4 lg:h-5  text-amber-300 group-hover:text-white duration-300"
                }
              />
            </button>
          </div>

          {/* ---> Location Image <--- */}
          <div className="mb-[2.6rem]">
            {/* ---> Description <--- */}

            <div className={"text-amber-300"}>Location Context:</div>
            <div className="md:text-md capitalize text-white mb-[2.6rem]">
              {locationContext[0].country}, {locationContext[0].city}
            </div>
            {sign.locationImage && <GatsbyImage image={locationImage} alt="" />}
          </div>

          {/* ---> Categories <--- */}
          <div className={`mb-[1.3rem]`}>
            <div className={"text-amber-300"}> Categories</div>
            <div className={`flex flex-wrap`}>
              {categories.map((category, index) => {
                return (
                  //* ---> Categories Link to single <--- *//
                  <Link
                    to={`/categories/${slugify(category.category)}`}
                    key={index}
                    className={` capitalize transition-colors`}
                  >
                    <span
                      className={`${undelineSM} hover:text-amber-300 duration-300 mr-5`}
                    >
                      {category.category}
                    </span>
                    {/* {index === categories.length - 1 ? (
                      <span className="!no-underline"> </span>
                    ) : (
                      <span className="!no-underline">{",\u00A0"}</span>
                    )} */}
                  </Link>
                );
              })}
            </div>
          </div>
          {/* ---> Design by <--- */}
          {design_by.length > 0 ? (
            <div className={"mb-[1.3rem]"}>
              <span className={"text-amber-300"}>Design by:</span>
              <div className={`flex`}>
                {design_by.map((designer, index) => {
                  return (
                    <div key={index} className="capitalize ">
                      {designer.organization}
                      {index === design_by.length - 1 ? "" : ",\u00A0"}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
          {/* ---> Concept by <--- */}
          {concept_by.length > 0 ? (
            <div>
              <span className={"text-amber-300"}>Concept by:</span>
              <div className={`flex`}>
                {concept_by.map((concept, index) => {
                  return (
                    <div key={index} className="capitalize mt-0">
                      {concept.organization}
                      {index === concept_by.length - 1 ? "" : ",\u00A0"}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
          {/* End 3/4 here */}
        </div>

        {/* ---> Back  Next buttons <--- */}
        <div
          className="flex justify-between"
          style={{ marginTop: `${1.3 * 6}rem` }}
        >
          <button
            className={"text-amber-300"}
            // to={"/signs"}
            onClick={() => navigate(-1)}
          >
            <div className="capitalize group flex items-center transition-colors">
              <ArrowRight
                classes={
                  "h-3.5 lg:h-4 rotate-180 pb-[0.2em] group-hover:text-white duration-300 mr-3"
                }
              />
              <div className="group-hover:text-white duration-300">back</div>
            </div>
          </button>

          <div className={`text-amber-300`}>
            <Link
              className={"flex items-center capitalize group transition-colors"}
              to={`/signs/${nextSign}`}
            >
              <div className="group-hover:text-white mr-3 duration-300">
                Next
              </div>
              <ArrowRight
                classes={
                  "h-3.5 lg:h-4 pt-[0.2em] group-hover:text-white duration-300"
                }
              />
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String) {
    strapiSign(slug: { eq: $slug }) {
      categories {
        category
      }
      locationContext {
        country
        city
      }
      concept_by {
        firstName
        organization
      }
      design_by {
        firstName
        organization
      }
      title
      year
      issuer
      description
      signal {
        externalLink {
          linkAddress
          linkTitle
        }
        signalHighlight
        signalContent {
          data {
            signalContent
          }
        }
        signalTitle
        slug
      }
      locationImage {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: NO_CHANGE
              width: 400
              quality: 100
              layout: CONSTRAINED
            )
          }
        }
      }
      heroImage {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: NO_CHANGE
              #width: 800
              height: 700
              quality: 100
              transformOptions: { fit: COVER }
              layout: CONSTRAINED
            )
          }
        }
      }
    }

    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default SignPageTemplate;

// *---> SEO
export const Head = () => (
  <Seo
    title={"Signs"}
    description={
      " Specualtive design Signs Exploring The Impact Of Extreme Climate Futures Through Signage Systems"
    }
    keywords={"specualtive design, sign, sign design"}
  />
);
