import * as React from "react";
import useKeyPress from "../utils/useKeyPressed";
import { CloseIcon, ArrowRight } from "../images/icons";
// import RichTextBlock from "./richTextBlock";
import { undelineSM } from "../utils/cssGroups";
import ReactMarkdown from "react-markdown";

export default function Modal({ visible, onClose, modalData }) {
  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      onClose();
    }
  }
  const pressed = useKeyPress("Escape");

  pressed && onClose();

  if (!visible) return null;
  return (
    // ---> Modal <--- //
    <div
      id={"signalModal"}
      // close when opacity backriund is clicked
      onClick={onClose}
      role={"button"}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      className={`pointer-events${
        visible ? "-true animate-pulse" : "-none"
      }  flex fixed z-50 inset-0 bg-black bg-opacity-50 transition-opacity duration-300 backdrop-blur-sm justify-center items-center ${
        visible ? "opacity-100" : "opacity-0"
      } `}
    >
      {/* ---> Close button  */}
      <div className="fixed right-[1rem] top-[1rem] sm:right-[2rem] sm:top-[2rem] md:right-[2rem] md:top-[2rem] lg:right-[3rem] lg:top-[3rem]">
        <div className="flex justify-end">
          <button onClick={onClose}>
            <CloseIcon classes={"text-white"} w={20} />
          </button>
        </div>
      </div>

      {/* ---> Modal Container <---  */}
      <div className="bg-amber-300 p-8 pr-[12px] sm:pr-[18px] w-4/5 h-4/5 md:w-3/4 md:h-3/4 overflow-y-auto hideScroll  flex flex-col">
        {/* ---> Modal Close <---  */}
        {/* <div className=" top-0 sticky">
          <div className="flex justify-end">
            <button onClick={onClose}>
              <CloseIcon classes={"text-black"} w={20} />
            </button>
          </div>
        </div>
 */}
        {/* ---> Modal Content <---  */}
        <div className="text-black flex flex-col justify-between h-full overflow-y-auto pr-5">
          <div>
            <p className="capitalize text-md md:text-lg mb-[1.3em]">
              {modalData.signalTitle}
            </p>

            <ReactMarkdown
              components={{
                img: ({ node, ...props }) => (
                  <img
                    className="w-full md:w-1/3 mt-[1.3em] "
                    alt={"image of " + modalData.signalTitle}
                    {...props}
                  />
                ),
                p: ({ node, ...props }) => (
                  <p className="md:text-md mb-[1.3em] last:mb-[0]" {...props} />
                ),
              }}
            >
              {modalData.signalContent.data.signalContent}
            </ReactMarkdown>
          </div>
          <div>
            <div className="md:text-md my-[1.3rem]">Links to Trends:</div>
            {modalData.externalLink.map((link, index) => {
              return (
                <a
                  key={index}
                  href={`${link.linkAddress}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  className={`${undelineSM} md:text-md  flex items-center mt-0 first:mt-[1.3rem] last:mb-[1.3rem]`}
                >
                  <ArrowRight
                    classes={
                      "h-2 w-[20px] pt-[0.1em] md:h-2 lg:h-3 text-black group-hover:text-white duration-300 mr-5"
                    }
                  />
                  <p className="m-0">{link.linkTitle}</p>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
